body {
    /*width: 100vh;*/
    /*height: 100vh;*/
    background-color: #f5f5f5;
}

#header {
    background-color: #f5f5f5;
    text-align: center;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    grid-area: header;
}

#footer {
    /*position: fixed;*/
    bottom: 0;
    width: 100%;
    text-align: center;
    background-color: #f5f5f5;
    z-index: 10;
    grid-area: footer;
}

#main-container{
    background-color: #f5f5f5;
}

/*.fixed-row-bottom { position: sticky; bottom: 0; width: 100%}*/
.center-text {
    text-align: center;
}

#scroll-to-top-btn {
    z-index: 100;
}

/* Style for the main container */
/*#main-container {*/
/*    margin-top: 6vh; !* Same as header height *!*/
/*    margin-bottom: 3vh; !* Same as footer height *!*/
/*    height: calc(80vh); !* Remaining viewport height *!*/
/*    align-items: center;*/
/*    !*background-color: antiquewhite;*!*/
/*}*/

/* Responsive adjustments for smaller screens */
@media screen and (max-height: 700px) {
    .main-container {
        /*margin-top: 15vh;*/
        /*margin-bottom: 15vh;*/
        height: calc(100% - 30vh);
    }
}

@media screen and (max-height: 600px) {
    .main-container {
        /*margin-top: 15vh;*/
        /*margin-bottom: 15vh;*/
        height: calc(100% - 40vh);
    }
}

#googleButton {
    width: 100% !important;
}

.css-container {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    grid-template-rows: auto;
    grid-template-areas:
    "header header header header"
    "main main main main"
    "main main main main"
    "footer footer footer footer";
}